<template>
  <div class="container">
    <div class="wrap">
      <span>
        <router-link class="item" active-class="active" :to="{name:'首页'}">首页</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'我的卫命'}">我的卫命</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'收件箱'}">收件箱
		
		
		<span v-if="mes_num>0" style="position: absolute;  
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: red;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2); margin-top: -8px;margin-left: ;"  >
  <p style="margin-left: 7px; margin-top: -10px; font-size: 12px; color: white;">{{mes_num}}</p></span></router-link>
      </span>  
	  
	  
      <span>
        <router-link class="item" active-class="active" :to="{name:'个人中心'}">个人中心</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'搜询医生'}">搜询医生</router-link>
      </span>
    <!--  <span>
        <router-link class="item" active-class="active" :to="{name:'商贸中心'}">商贸中心</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'资讯'}">资讯</router-link>
      </span> -->
     <span>
        <router-link class="item" active-class="active" :to="{name:'学习科普'}">学习科普</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'招聘求职'}">招聘求职</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'海外合作'}">海外合作</router-link>
      </span>
	  
	 <span>
	    <router-link class="item" active-class="active" :to="{name:'关于我们'}">关于我们</router-link>
	  </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'联系客服'}"> 联系客服</router-link>
      </span>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "NavBar",
	data() {
	  return { 
		num:13,
		mes_num:0
	  }
	},
	 
	
	created() {
		this.timer = setInterval(() => {
			// 业务逻辑	 
			//患者情况下  是否支付咨询费
		 
				const params_pay = new URLSearchParams();
				params_pay.append('uid', sessionStorage.getItem('uid'));
				params_pay.append('did', this.did);
		
				axios.post(this.apiUrl + '/api/user/getChatPay', params_pay)
					.then((response) => {
						if (response.data.code == 1) {
							if (this.show_pay_msg == 1) {
								this.show_pay_msg = 22;
								this.show_pay = 1;
		
								var g = new Date().getTime();
								const goMes = sessionStorage.getItem('uid') + '_' + this.did + '_' + this
									.textarea + '_6_' + g;
		
								//alert(goMes);
								socket.emit('hello', goMes);
							}
						}
		
					})
					.catch(function(error) {
						console.log(error);
					});
		 
		 if(sessionStorage.getItem('uid')){
			 //监听消息数量
			 const params_mess = new URLSearchParams();
			 params_mess.append('uid', sessionStorage.getItem('uid')); 
			 params_mess.append('type', sessionStorage.getItem('type')); 
			 
			 axios.post(this.apiUrl + '/api/user/getMesNum', params_mess)
			 	.then((response) => {
			 				//alert(1);
			 						this.mes_num = response.data.data
			 		
			 	})
			 	.catch(function(error) {
			 		console.log(error);
			 	});
			 			
		 }

			
		
		
		}, 2000)
	
	},
  }
</script>



<style scoped>
.container{
  width: 100%;
  line-height: 40px;
  height: 40px;
  background-color: #fff;
}
  .wrap{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .item{
    text-decoration: none;
    color: #3B383B;
    font-size: 18px;
  }
  .active{
    text-decoration: none;
    color: #51CBDF;
  }
</style>
